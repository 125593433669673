<template>
  <v-container class="bg-light pa-3 pa-md-10 d-block" fluid fill-height >
    <template v-if="pageContent != null">
      <div class="w-100 align-self-start">
        <!-- LOADER -->
        <div class="liScreenLoading" v-if="screenLoading">
          <v-progress-circular indeterminate color="primary" class="ma-auto" />
        </div>

        <h1 class="text-h5 text-md-h4 font-weight-bold w-100 mb-3 d-flex">
          {{ pageContent.title }} <span v-if="relations.length > 0" class="ml-3 body-2 align-self-top">({{ relations.length }})</span>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="2" v-bind="attrs" v-on="on" @click.prevent="newItem" color="primary" fab dark class="ml-auto mr-0" small> <v-icon> mdi-plus </v-icon></v-btn>
            </template>
            <span>{{ pageContent.addRelationLabel }}</span>
          </v-tooltip>
        </h1>
        <v-sheet class="pa-3 pa-md-5">
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="pageContent.searchLabel" single-line hide-details />
        </v-sheet>
        <v-data-table :mobile-breakpoint="0" :no-data-text="pageContent.noDataText" :no-results-text="pageContent.noResultsText" :headers="headers" sort-by="first_name" :loading="relations == null" :footer-props="{ 'items-per-page-options': itemsPerPage, 'items-per-page-text': pageContent.rowsPerPageText, 'items-per-page-all-text': pageContent.rowsPerPageAllText }" :items="relations" :items-per-page="50" class="w-100" :search="search" :class="{ mobile: isAppMobile }">
          <template v-slot:item.first_name="{ item }">
            <div class="d-flex">
              <v-avatar color="primary" size="36" class="mr-3 font-weight-bold my-3">
                <span class="white--text">{{ liGetInitials(`${item.first_name} ${item.last_name}`) }}</span>
              </v-avatar>
              <div class="text-left my-auto">
                <span class="font-weight-bold w-100"> {{ item.first_name }} {{ item.last_name }} </span>
              </div>
            </div>
          </template>
          <template v-slot:item.last_name="{ item }">
            <div class="d-none">
              {{ item.last_name }}
            </div>
          </template>
          <template v-slot:item.email="{ item }">
            <span class="font-weight-bold" v-if="isAppMobile">
              {{ item.first_name }} {{ item.last_name }} <br />
              <span class="font-weight-light body-4"> {{ item.email }} </span>
            </span>
            <template v-else>
              {{ item.email }}
            </template>
          </template>
          <template v-slot:item.relationship_type_id="{ item }">
            <v-chip color="light" x-small> {{ getRelationTypeLabel(item.relationship_type_id) }} </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="min-width: 80px">
              <v-icon small class="mr-6 mr-md-2" @click="editItem(item)"> mdi-pencil </v-icon>
              <!-- DELETE -->
              <v-menu close-on-content-click left offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on"> mdi-delete </v-icon>
                </template>
                <v-sheet class="pa-2 bg-white">
                  <p class="px-1 py-1 body-2 mb-0">{{ pageContent.remove.sure }}</p>
                  <div class="pt-3 px-1">
                    <v-btn color="red" class="w-100" dark @click="deleteItem(item)">{{ pageContent.remove.remove }}</v-btn>
                  </div>
                </v-sheet>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </div>
    </template>
    <template v-else>
      <div class="d-flex w-100 " style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="ma-auto" />
      </div>
    </template>
  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "Relations",
  data() {
    return {
      apiPublicUrl: API_URL,
      editedIndex: -1,
      editedItem: null,
      itemsPerPage: [25, 50, 100, -1],
      search: "",
      screenLoading: false,
    };
  },
  created() {
    this.fetchURL(`/${this.language}/relations`);
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
  },
  mounted() {
    console.log(`START -- ${this.$options.name} --  VIEW`);
    console.log("language", this.language);
    console.log("website", this.website);
    console.log("fullUrl", this.fullUrl);
    console.log("END -- VIEW");
    if (this.website != null) {
      this.fetchPageData();
    }
  },
  computed: {
    headers() {
      if (this.isAppMobile) {
        return [
          {
            text: this.pageContent.headers.name,
            align: "start",
            sortable: true,
            value: "email",
          },
          {
            text: this.pageContent.headers.relationshipType,
            align: "start",
            sortable: true,
            value: "relationship_type_id",
          },
          {
            text: this.pageContent.headers.actions,
            align: "end",
            sortable: false,
            value: "actions",
          },
        ];
      } else {
        return [
          {
            text: this.pageContent.headers.name,
            align: "start",
            sortable: true,
            value: "first_name",
          },
          {
            text:'',
            align: "start",
            sortable: true,
            value: "last_name",
          },
          {
            text: this.pageContent.headers.email,
            align: "start",
            sortable: true,
            value: "email",
          },
          {
            text: this.pageContent.headers.relationshipType,
            align: "start",
            sortable: true,
            value: "relationship_type_id",
          },
          {
            text: this.pageContent.headers.actions,
            align: "end",
            sortable: false,
            value: "actions",
          },
        ];
      }
    },
    clangID() {
      return this.$store.getters["liApiStore/getClangID"];
    },
    relations() {
      return this.$store.getters["liApiStore/getRelations"];
    },
    invites() {
      return this.$store.getters["liApiStore/getInvites"];
    },
    timeSlots() {
      return this.$store.getters["liApiStore/getTimeSlots"];
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
  },
  methods: {
    startLoading() {
      this.screenLoading = true;
      document.body.classList.add("liLoading");
    },
    stopLoading() {
      this.screenLoading = false;
      document.body.classList.remove("liLoading");
    },
    newItem() {
      this.editedItem = Object.assign({ first_name: "", last_name: "", email: "", relationship_type_id: "1" });
      const OPTIONS = {
        type: "new",
        color: "white",
        title: `${this.pageContent.modal.newTitle}`,
        relation: this.editedItem,
        labels: {
          saveText: this.defaultTextContent.defaultButtonTexts.save,
          cancelText: this.defaultTextContent.defaultButtonTexts.cancel,
          firstName: this.pageContent.modal.firstName,
          lastName: this.pageContent.modal.lastName,
          email: this.pageContent.modal.email,
          relationType: this.pageContent.modal.relationType,
        },
      };
      this.$root.$children[0].$refs.liRelationModal.open(OPTIONS).then((resp) => {
        if (resp) {
          this.startLoading();
          let clangId = this.$store.getters["liApiStore/getClangID"];
          if (clangId != null && clangId != undefined) {
            const _type = this.getRelationTypeLabel(resp.relationship_type_id);
            const relationInsertData = {
              clangID: clangId,
              relation: {
                first_name: resp.first_name,
                last_name: resp.last_name,
                email: resp.email,
                relationship_type: _type,
                relationship_type_id: resp.relationship_type_id,
              },
            };
            this.$store.dispatch("liApiStore/ADD_NEW_RELATION", relationInsertData).then(() => {
              this.$store.dispatch("liApiStore/GET_RELATIONS", clangId).then(() => {
                this.resetEditingItem();
                this.$root.$children[0].$refs.liToast.show({ message: this.pageContent.modal.itemAddSuccesMessage, color: 'success', icon: 'mdi-check' })
              });
            });
          } else {
            this.resetEditingItem();
          }
        } else {
          console.log("Cancel", resp);
          this.resetEditingItem();
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.relations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const OPTIONS = {
        type: "edit",
        color: "white",
        title: this.isAppMobile ? `${this.pageContent.modal.editTitle}` : `${this.pageContent.modal.editTitle} ${this.editedItem.first_name} ${this.editedItem.last_name}`,
        relation: this.editedItem,
        labels: {
          saveText: this.defaultTextContent.defaultButtonTexts.save,
          cancelText: this.defaultTextContent.defaultButtonTexts.cancel,
          firstName: this.pageContent.modal.firstName,
          lastName: this.pageContent.modal.lastName,
          email: this.pageContent.modal.email,
          relationType: this.pageContent.modal.relationType,
        },
      };
      this.$root.$children[0].$refs.liRelationModal.open(OPTIONS).then((resp) => {
        if (resp) {
          this.startLoading();
          let clangId = this.$store.getters["liApiStore/getClangID"];
          if (clangId != null && clangId != undefined) {
            const relationUpdateData = {
              clangID: clangId,
              relation: resp.clang_id,
              updateInfo: {
                first_name: resp.first_name,
                last_name: resp.last_name,
                email: resp.email,
                relationship_type: resp.relationship_type,
                relationship_type_id: resp.relationship_type_id,
              },
            };
            this.$store.dispatch("liApiStore/UPDATE_RELATION", relationUpdateData).then(() => {
              this.$store.dispatch("liApiStore/GET_RELATIONS", clangId).then(() => {
                this.resetEditingItem();
                this.$root.$children[0].$refs.liToast.show({ message: this.pageContent.modal.itemUpdateSuccesMessage, color: 'success', icon: 'mdi-check' })
              });
            });
          } else {
            this.resetEditingItem();
          }
        } else {
          console.log("Cancel", resp);
          this.resetEditingItem();
        }
      });
    },
    resetEditingItem() {
      setTimeout(() => {
        this.editedItem = null;
        this.stopLoading();
      }, 50);
    },
    deleteItem(item) {
      let clangId = this.$store.getters["liApiStore/getClangID"];
      if (clangId != null && clangId != undefined) {
        this.startLoading();
        const relationDeleteData = {
          clangID: clangId,
          relation: item.clang_id,
        };
        this.$store.dispatch("liApiStore/DELETE_RELATION", relationDeleteData).then(() => {
          this.$store.dispatch("liApiStore/GET_RELATIONS", clangId).then(() => {
            this.$root.$children[0].$refs.liToast.show({ message: this.pageContent.modal.itemRemoveSuccesMessage, color: 'warning', icon: 'mdi-check' })
            this.stopLoading();
          });
        });
      }
    },
  },
};
</script>
