<template>
  <v-container class="fill-height" fluid style="height: 100vh; min-height: 600px"> Page not found </v-container>
</template>

<script>
export default {
  title: "404 - Page not found",
  name: "PageNotFound",
};
</script>
