<template>
  <v-container class="bg-light pa-3 pa-md-10 d-block" fluid fill-height>
    <template v-if="pageContent != null && timeSlots.length != 0">
      <v-stepper v-model="step" non-linear dark>
        <v-stepper-header>
          <v-stepper-step edit-icon="mdi-check" :editable="step > 1 && step != 4" :complete="step > 1" step="1"> {{ pageContent.titleStep1 }} </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step edit-icon="mdi-check" :editable="selected.length > 0 && step > 2 && step != 4" :complete="step > 2" step="2"> {{ pageContent.titleStep2 }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="step > 3">{{ pageContent.titleStep3 }} </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items class="white">
          <!-- STEP 1 - SELECT RELATIONS -->
          <v-stepper-content step="1">
            <v-sheet min-height="83vh" light>
              <template v-if="notIvitedRelations != null">
                <h1 class="text-h6 text-md-h4 font-weight-bold w-100 mb-3 text-center mt-5">
                  {{ pageContent.step1.title }}
                </h1>
                <span v-if="pageContent.step1.subTitle != ''" class="w-100 text-center d-block" v-html="pageContent.step1.subTitle" />
                <v-card class="mb-5" elevation="0">
                  <v-card-title>
                    <div class="w-100">
                      <div class="mb-md-5">
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="pageContent.step1.searchLabel" single-line hide-details class="d-flex" />
                      </div>
                      <template v-if="!isAppMobile">
                        <div class="d-block d-md-flex mb-5" style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)">
                          <div class="pr-6 body-2 d-block d-md-flex">
                            <span class="my-auto"> {{ pageContent.step1.selectTitle }} </span>
                          </div>
                          <v-chip-group column>
                            <v-chip text small class="mr-3" @click.prevent="selectRelations('all')" style="overflow: unset">
                              {{ defaultTextContent.relationTypesAllText }}
                              <v-badge color="grey darken-1" :content="notIvitedRelations.length" inline class="liAmountBadge"></v-badge>
                            </v-chip>
                            <template v-for="(type, index) in defaultTextContent.relationTypes">
                              <v-chip text :key="index" small class="mr-3" @click.prevent="selectRelations(type)" style="overflow: unset">
                                {{ type.name }}
                                <v-badge color="grey darken-1" :content="calcAmountOfRelationType(type.value)" inline class="liAmountBadge"></v-badge>
                              </v-chip>
                            </template>
                            <v-chip small class="mr-2" @click.prevent="selectRelations('none')" close @click:close="selectRelations('none')" v-if="selected.length > 0" outlined>
                              <span class="mr-1">{{ defaultTextContent.relationTypesNoneText }}</span>
                            </v-chip>
                          </v-chip-group>
                        </div>
                      </template>
                      <template v-else>
                        <v-select item-text="name" v-model="selectedTypeMobile" class="mt-3" item-value="valueString" clearable :items="defaultTextContent.relationTypes" :label="pageContent.step1.selectTitle" @change="selectRelationsMobile()">
                          <template v-slot:prepend-item>
                            <v-list-item ripple @click.prevent="selectRelationsMobile('all')">
                              <v-list-item-content>
                                <v-list-item-title> {{ defaultTextContent.relationTypesAllText }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item ripple v-if="selected.length > 0" @click.prevent="selectRelationsMobile('none')">
                              <v-list-item-content>
                                <v-list-item-title> {{ defaultTextContent.relationTypesNoneText }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </template>
                    </div>
                  </v-card-title>
                  <v-data-table
                    v-model="selected"
                    :mobile-breakpoint="0"
                    return-object
                    show-select
                    :headers="headers"
                    sort-by="first_name"
                    @click:row="addToSelection"
                    item-key="clang_id"
                    :no-data-text="pageContent.step1.noDataText"
                    :no-results-text="pageContent.step1.noResultsText"
                    :loading="notIvitedRelations == null"
                    :footer-props="{ 'items-per-page-options': itemsPerPage, 'items-per-page-text': pageContent.rowsPerPageText, 'items-per-page-all-text': pageContent.rowsPerPageAllText }"
                    :items="notIvitedRelations"
                    :items-per-page="50"
                    class="w-100"
                    :search="search"
                    :class="{ mobile: isAppMobile }"
                  >
                    <template v-slot:item.first_name="{ item }">
                      <div class="d-flex">
                        <v-avatar color="primary" size="36" class="mr-3 font-weight-bold my-3">
                          <span class="white--text">{{ liGetInitials(`${item.first_name} ${item.last_name}`) }}</span>
                        </v-avatar>
                        <div class="text-left my-auto">
                          <span class="font-weight-bold w-100"> {{ item.first_name }} {{ item.last_name }} </span>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.last_name="{ item }">
                      <div class="d-none">
                        {{ item.last_name }}
                      </div>
                    </template>
                    <template v-slot:item.email="{ item }">
                      <span class="font-weight-bold" v-if="isAppMobile">
                        {{ item.first_name }} {{ item.last_name }} <br />
                        <span class="font-weight-light body-4"> {{ item.email }} </span>
                      </span>
                      <template v-else>
                        {{ item.email }}
                      </template>
                    </template>
                    <template v-slot:item.relationship_type_id="{ item }">
                      <v-chip color="light" x-small> {{ getRelationTypeLabel(item.relationship_type_id) }} </v-chip>
                    </template>
                  </v-data-table>
                  <v-card-actions class="w-100 mt-10 d-block d-md-flex">
                    <v-btn color="primary" :block="isAppMobile" :disabled="!selected.length > 0" elevation="0" class="mx-auto px-16" @click="step = 2">
                      <span v-if="selected.length > 0" class="font-weight-bold mr-3">({{ selected.length }})</span> {{ pageContent.step1.buttons.next }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-sheet>
          </v-stepper-content>
          <!-- STEP 2 - AMOUNT OF TICKETS -->
          <v-stepper-content step="2">
            <v-sheet min-height="83vh" light>
              <v-card class="mb-5" elevation="0">
                <h1 class="text-h6 text-md-h4 font-weight-bold w-100 mb-3 text-center mt-5">
                  {{ pageContent.step2.title }}
                </h1>
                <span class="w-100 text-center d-block" v-html="pageContent.step2.subTitle" />
                <v-sheet max-width="400" class="mx-auto mt-10 d-block">
                  <v-select filled dense hide-details v-model="amountOfTickets" :items="pageContent.step2.amountOfTickets.options" item-text="label" item-value="value" />
                </v-sheet>
                <v-card-actions class="w-100 mt-10 d-block d-md-flex">
                  <v-btn text elevation="0" :block="isAppMobile" class="mb-2 mb-md-0 ml-md-auto mr-md-3 px-16" @click="step = 1"> {{ pageContent.step2.buttons.back }} </v-btn>
                  <v-btn color="primary" :block="isAppMobile" elevation="0" class="ml-0 mb-2 mb-md-0 mr-md-auto ml-md-3 px-16" @click="step = 3"> {{ pageContent.step2.buttons.next }} </v-btn>
                </v-card-actions>
              </v-card>
            </v-sheet>
          </v-stepper-content>
          <!-- STEP 3 - CHECK -->
          <v-stepper-content step="3">
            <v-sheet min-height="83vh" light>
              <v-card class="mb-5" elevation="0">
                <h1 class="text-h6 text-md-h4 font-weight-bold w-100 mb-3 text-center mt-5">
                  {{ pageContent.step3.title }}
                </h1>
                <span class="w-100 text-center d-block body-2" v-html="formatStep3Subtitle()" />
                <span class="w-100 text-center d-block body-3 mt-5 mb-2" v-html="pageContent.step3.ticketsTitle" />
                <div class="w-100 flex-column my-3" :class="{ 'd-block': isAppMobile, 'd-flex': !isAppMobile }">
                  <template v-for="r in defaultTextContent.relationTypes">
                    <div :key="r.value" class="body-3 mx-auto py-2 pl-5 pr-8" :class="{ 'd-block': isAppMobile, 'd-flex': !isAppMobile }">
                      <div :style="isAppMobile ? '' : 'min-width: 200px'">
                        <v-chip x-small>{{ r.name }}</v-chip>
                      </div>
                      <span class="grey--text"> {{ defaultTextContent.accesToTimeSlotLabel }} </span>
                      <b class="ml-5 float-end"> {{ r.accesToTimeSlotsLabel }}</b>
                    </div>
                  </template>
                </div>
                <v-sheet elevation="0" class="my-3">
                  <v-list dense>
                    <v-list-item class="listHeader v-list-item--disabled">
                      <v-list-item-content>
                        <div class="d-flex justify-space-between">
                          <div class="d-inline-block my-auto" :class="{ 'w-50': !isAppMobile }">
                            {{ pageContent.step3.headers.name }}
                          </div>
                          <div class="w-25 px-3" v-if="!isAppMobile">
                            {{ pageContent.step3.headers.amountOfTickets }}
                          </div>
                          <div class="w-25 px-3" v-if="!isAppMobile">
                            {{ pageContent.step3.headers.relationshipType }}
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-for="(invite, index) in invites">
                      <v-list-item :key="index" class="listHover">
                        <v-list-item-content>
                          <div :class="{ 'd-flex flex-column w-100': isAppMobile }">
                            <div class="d-inline-block my-auto w-50" :class="{ 'w-50': !isAppMobile }">
                              <div class="d-flex">
                                <v-avatar color="primary" size="36" class="mr-3 font-weight-bold my-3">
                                  <span class="white--text">{{ liGetInitials(`${invite.firstname} ${invite.lastname}`) }}</span>
                                </v-avatar>
                                <div class="my-auto">
                                  <span class="font-weight-bold mb-2">{{ invite.firstname }} {{ invite.lastname }}</span> <br />
                                  <span class="grey--text body-3">{{ invite.email }}</span>
                                </div>
                              </div>
                            </div>
                            <div :class="{ 'd-inline-block my-auto w-25 px-3': !isAppMobile, 'my-3': isAppMobile }">
                              <v-select filled dense hide-details v-model="invite.ticketQty" :items="pageContent.step2.amountOfTickets.options" item-text="label" item-value="value" />
                            </div>
                            <div :class="{ 'd-inline-block my-auto w-25 px-3': !isAppMobile, 'my-3': isAppMobile }">
                              <v-select filled dense hide-details chips v-model="invite.relationshipTypeID" :items="defaultTextContent.relationTypes" item-text="name" item-value="value" />
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-sheet>
                <v-card-actions class="w-100 mt-10 d-block d-md-flex">
                  <v-btn text elevation="0" :block="isAppMobile" class="mb-2 mb-md-0 ml-md-auto mr-md-3 px-16" @click="step = 2"> {{ pageContent.step3.buttons.back }} </v-btn>
                  <v-btn color="primary" :block="isAppMobile" :loading="isSending" :disabled="isSending" elevation="0" class="ml-0 mb-2 mb-md-0 mr-md-auto ml-md-3 px-16" @click="sendInvites()"> {{ pageContent.step3.buttons.next }} </v-btn>
                </v-card-actions>
              </v-card>
            </v-sheet>
          </v-stepper-content>
          <!-- STEP 4 - SEND -->
          <v-stepper-content step="4">
            <v-sheet min-height="83vh" light>
              <v-card class="mb-5" elevation="0">
                <h1 class="text-h6 text-md-h4 font-weight-bold w-100 mb-3 text-center mt-5">
                  {{ pageContent.step4.title }}
                </h1>
                <span class="w-100 text-center d-block body-2" v-html="formatStep4Subtitle()" />

                <v-sheet elevation="0" class="my-3"></v-sheet>
                <v-card-actions class="w-100 mt-10 d-block d-md-flex"> </v-card-actions>
              </v-card>
            </v-sheet>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <template v-else-if="timeSlots.length == 0">
      <div v-html="defaultTextContent.timeSlots.noTimeSlots" />
    </template>
    <template v-else>
      <div class="d-flex w-100" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="ma-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
import { sprintf } from "../../node_modules/sprintf-js";
import _ from "lodash";
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "Invite",
  data() {
    return {
      apiPublicUrl: API_URL,
      itemsPerPage: [25, 50, 100, -1],
      search: "",
      step: 1,
      amountOfTickets: "1",
      selectedTypeMobile: "",
      selected: [],
      invites: [],
      isSending: false,
    };
  },
  created() {
    this.fetchURL(`/${this.language}/invite`);
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
    step(val) {
      if (val == 3) {
        this.prepInvites();
      }
    },
  },
  mounted() {
    console.log(`START -- ${this.$options.name} --  VIEW`);
    console.log("language", this.language);
    console.log("website", this.website);
    console.log("fullUrl", this.fullUrl);
    console.log("END -- VIEW");
    if (this.website != null) {
      this.fetchPageData();
    }
  },
  computed: {
    headers() {
      if (this.isAppMobile) {
        return [
          {
            text: this.pageContent.headers.name,
            align: "start",
            sortable: true,
            value: "email",
          },
          {
            text: this.pageContent.headers.relationshipType,
            align: "start",
            sortable: true,
            value: "relationship_type_id",
          },
          {
            text: this.pageContent.headers.actions,
            align: "end",
            sortable: false,
            value: "actions",
          },
        ];
      } else {
        return [
          {
            text: this.pageContent.headers.name,
            align: "start",
            sortable: true,
            value: "first_name",
          },
          {
            text: '',
            align: "start",
            sortable: true,
            value: "last_name",
          },
          {
            text: this.pageContent.headers.email,
            align: "start",
            sortable: true,
            value: "email",
          },
          {
            text: this.pageContent.headers.relationshipType,
            align: "start",
            sortable: true,
            value: "relationship_type_id",
          },
          {
            text: this.pageContent.headers.actions,
            align: "end",
            sortable: false,
            value: "actions",
          },
        ];
      }
    },
    clangID() {
      return this.$store.getters["liApiStore/getClangID"];
    },
    relations() {
      return this.$store.getters["liApiStore/getRelations"];
    },
    invitations() {
      return this.$store.getters["liApiStore/getInvitations"];
    },
    notIvitedRelations: {
      get() {
        if (this.invitations != null && this.relations != null) {
          return this.relations.filter((_relation) => _relation.ticket_state != "uitgenodigd");
        } else {
          return null;
        }
      },
    },
    timeSlots() {
      return this.$store.getters["liApiStore/getTimeSlots"];
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
  },
  methods: {
    calcAmountOfRelationType(_value) {
      if (this.notIvitedRelations != null && !_.isEmpty(String(_value)) && _value != "") {
        let total = this.notIvitedRelations.filter((_relation) => Number(_relation.relationship_type_id) == Number(_value));
        return String(total.length);
      } else {
        return "0";
      }
    },
    addToSelection(_row) {
      if (this.selected.indexOf(_row) == -1) {
        this.selected.push(_row);
      } else {
        this.selected.splice(this.selected.indexOf(_row), 1);
      }
    },
    formatStep3Subtitle() {
      return sprintf(this.pageContent.step3.subTitle, this.invites.length);
    },
    formatStep4Subtitle() {
      return this.pageContent.step4.subTitle;
      // return sprintf(this.pageContent.step4.subTitle, this.invites.length);
    },
    selectRelations(_type) {
      if (_type == "all") {
        this.selected = this.notIvitedRelations;
      } else if (_type == "none") {
        this.selected = [];
      } else {
        this.selected = this.notIvitedRelations.filter((_relation) => _relation.relationship_type_id == _type.value);
      }
    },
    selectRelationsMobile(_type = null) {
      if (_type == "all") {
        this.selected = this.notIvitedRelations;
      } else if (_type == "none") {
        this.selected = [];
      } else {
        this.selected = this.notIvitedRelations.filter((_relation) => _relation.relationship_type_id == this.selectedTypeMobile);
      }
    },
    prepInvites() {
      this.invites = [];
      this.selected.forEach((invitee) => {
        this.invites.push({
          email: invitee.email,
          firstname: invitee.first_name,
          lastname: invitee.last_name,
          relationID: invitee.clang_id,
          relationshipType: invitee.relationship_type, // We should omit this attribute at all costs
          relationshipTypeID: !_.isEmpty(invitee.relationship_type_id) ? Number(invitee.relationship_type_id) : 4, // We should ONLY use this attribute
          ticketQty: this.amountOfTickets,
        });
      });
    },
    sendInvites() {
      this.isSending = true;
      // this.$store.dispatch("liApiStore/SET_STORE_LOADING", true);
      let data = {
        clangID: this.clangID,
        lang: this.language.toUpperCase(),
        emailID: this.emailInviteId[this.language.toUpperCase()],
        inviteDate: this.localeDateFormat(new Date()),
        ticketQty: 1,
        customers: this.invites,
      };

      if (data.customers.length > 10) {
        // PROCESSING MORE THEN 10 CUSTOMERS TAKES TO LONG BUT WILL WORK (EVENTUALLY) SO JUST SEND IT OUT
        this.$store.dispatch("liApiStore/SEND_INVITE", data);
        // AND FAKE THE PROCESS
        setTimeout(() => {
          const clangId = this.$store.getters["liApiStore/getClangID"];
          this.$store.dispatch("liApiStore/GET_RELATIONS", clangId).then(() => {
            this.step = 4;
            setTimeout(() => {
              this.$root.$children[0].$refs.liToast.show({ message: this.pageContent.step3.sendingMultipleSuccess, color: "success", icon: "mdi-check" });
              this.isSending = false;
              this.$store.dispatch("liApiStore/SET_STORE_LOADING", false);
            }, 100);
          });
        }, 2000);
      } else {
        this.$store.dispatch("liApiStore/SEND_INVITE", data).then(() => {
          const clangId = this.$store.getters["liApiStore/getClangID"];
          this.$store.dispatch("liApiStore/GET_RELATIONS", clangId).then(() => {
            this.step = 4;
            setTimeout(() => {
              this.$root.$children[0].$refs.liToast.show({ message: this.pageContent.step3.sendingSuccess, color: "success", icon: "mdi-check" });
              this.isSending = false;
              this.$store.dispatch("liApiStore/SET_STORE_LOADING", false);
            }, 100);
          });
        });
      }
    },
  },
};
</script>
