<template>
  <v-container fluid fill-height v-if="defaultTextContent != null">
    <div class="text-center ma-auto">
      <h1 v-html="defaultTextContent.notAllowed.title" class="font-weight-bold mb-5 text-h2" />
      <p v-html="defaultTextContent.notAllowed.description" class="mb-15" />
    </div>
  </v-container>
</template>

<script>
export default {
  title: "402 - Not Allowed",
  name: "NotAllowed",
  mounted() {
    console.log(`START -- ${this.$options.name} --  VIEW`);
    console.log("language", this.language);
    console.log("website", this.website);
    console.log("fullUrl", this.fullUrl);
    console.log("END -- VIEW");
  },
  watch: {
    defaultTextContent(val) {
      if (val != null) {
        this.$store.dispatch("liApiStore/SET_STORE_LOADING", false).then(() => {
          console.log("test");
        });
      }
    },
  },
};
</script>
