<template>
  <v-container class="bg-light pa-3 pa-md-10 d-block" fluid fill-height>
    <template v-if="pageContent != null && timeSlots.length != 0">
      <h1 class="text-h5 text-md-h4 font-weight-bold w-100 mb-3 d-flex">
        {{ pageContent.title }} <span v-if="mappedInvitations.length > 0" class="ml-3 body-2 align-self-top">({{ mappedInvitations.length }})</span>
      </h1>
      <v-container fluid class="px-0 mt-3">
        <ul class="liGridWrapper small">
          <template v-for="(timeSlot, index) in timeSlots">
            <li class="" :key="index">
              <v-card class="pt-1 px-2" elevation="0">
                <p class="font-weight-bold mb-1">{{ pageContent.timeSlot }} {{ timeSlot.time_slot }}</p>
                <div class="body-4 mb-1">
                  {{ capitalizeFirstLetter(localeDateFormat(timeSlot.time_slot_date, "manual", "long")) }}<br />
                  {{ timeSlot.time_slot_time }}<br />
                </div>
                <v-progress-linear :value="parseInt(Math.round((timeSlot.tickets_claimed / timeSlot.tickets_total) * 100))" class="w-100"></v-progress-linear>
                <div class="text-right body-5">{{ timeSlot.tickets_claimed }} / {{ timeSlot.tickets_total }}</div>
              </v-card>
            </li>
          </template>
        </ul>
      </v-container>
      <v-sheet class="pa-3 pa-md-5">
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="pageContent.searchLabel" single-line hide-details />
      </v-sheet>
      <v-data-table
        :mobile-breakpoint="0"
        :no-data-text="pageContent.noDataText"
        :no-results-text="pageContent.noResultsText"
        :headers="headers"
        :expanded.sync="expanded"
        expand-icon="mdi-chevron-right"
        show-expand
        single-expand
        item-key="clang_id"
        sort-by="first_name"
        @click:row="expand"
        :loading="mappedInvitations == null"
        :items="mappedInvitations"
        :footer-props="{ 'items-per-page-options': itemsPerPage, 'items-per-page-text': pageContent.rowsPerPageText, 'items-per-page-all-text': pageContent.rowsPerPageAllText }"
        :items-per-page="50"
        class="w-100"
        :search="search"
        :hide-default-header="isAppMobile"
        :class="{ mobile: isAppMobile }"
      >
        <template v-slot:item.first_name="{ item }">
          <v-avatar color="primary" size="36" class="mr-3 font-weight-bold my-3" v-if="!isAppMobile">
            <span class="white--text">{{ liGetInitials(`${item.first_name} ${item.last_name}`) }}</span>
          </v-avatar>
          <span class="font-weight-bold" v-if="!isAppMobile"> {{ item.first_name }} {{ item.last_name }} </span>
        </template>
        <template v-slot:item.last_name="{ item }">
          <div class="d-none">
            {{ item.last_name }}
          </div>
        </template>
        <template v-slot:item.email="{ item }">
          <span class="font-weight-bold" v-if="isAppMobile">
            {{ item.first_name }} {{ item.last_name }} <br />
            <span class="font-weight-light body-4"> {{ item.email }} </span>
          </span>
          <template v-else>
            {{ item.email }}
          </template>
        </template>
        <template v-slot:item.barcodes="{ item }">
          {{ item.barcodes.length }}
        </template>
        <template v-slot:item.barcodeStatus="{ item }">
          <span class="d-flex">
            <span class="my-auto" v-html="barcodesStatus(item.barcodes)" style="min-width: 185px; display: flex" :class="{ 'body-4': isAppMobile }" />
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="my-auto mr-0 ml-auto" icon small v-bind="attrs" v-on="on" @click.stop="sendExtraTicket(item)">
                  <v-progress-circular indeterminate :size="18" v-if="sendingExtraTicketRelation == item.clang_id" />
                  <v-icon v-else small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>
                {{ sendingExtraTicketRelation == item.clang_id ? pageContent.extraSending : pageContent.extraTicket }}
              </span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="grey lighten-2 px-0" :colspan="headers.length">
            <div class="pa-2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ pageContent.subHeaders.barcode }}</th>
                      <th class="text-left">{{ pageContent.subHeaders.dateSend }}</th>
                      <!-- <th class="text-left">{{ pageContent.subHeaders.type }}</th> -->
                      <th class="text-left">{{ pageContent.subHeaders.status }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="_code in item.barcodes" :key="_code.barcode">
                      <td style="min-width: 120px">{{ _code.barcode }}</td>
                      <td style="min-width: 180px">{{ localeDateFormat(item.invite_date) }}</td>
                      <td style="min-width: 180px">
                        <template v-if="_code.isUsed == 'yes'">
                          {{ pageContent.downloaded }}
                          <b> {{ _code.firstname }} {{ _code.lastname }} </b>
                          <i class="ml-3 grey--text">{{ getTimeSlot(_code.customerClangId) }} </i>
                        </template>
                        <template v-else>
                          <div class="d-flex w-100">
                            <div class="my-auto">
                              {{ pageContent.notDownloaded }}
                            </div>
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-0 ml-auto" icon small v-bind="attrs" v-on="on" @click.prevent="resendTicket(item, _code)">
                                  <v-progress-circular indeterminate :size="18" v-if="resendingBarcode == _code.barcode" />
                                  <v-icon v-else small>mdi-send-clock</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                {{ resendingBarcode == _code.barcode ? pageContent.reSending : pageContent.resendInvite }}
                              </span>
                            </v-tooltip>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </td>
        </template>
      </v-data-table>
    </template>
    <template v-else-if="timeSlots.length == 0">
      <div v-html="defaultTextContent.timeSlots.noTimeSlots" />
    </template>
    <template v-else>
      <div class="d-flex w-100" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="ma-auto" />
      </div>
    </template>
  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;

import _ from "lodash";
import { sprintf } from "../../node_modules/sprintf-js";
export default {
  title: "",
  name: "Invitations",
  data() {
    return {
      apiPublicUrl: API_URL,
      search: "",
      expanded: [],
      itemsPerPage: [25, 50, 100, -1],
      resendingBarcode: null,
      sendingExtraTicketRelation: null,
    };
  },
  created() {
    this.fetchURL(`/${this.language}/invitations`);
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
  },
  mounted() {
    console.log(`START -- ${this.$options.name} --  VIEW`);
    console.log("language", this.language);
    console.log("website", this.website);
    console.log("fullUrl", this.fullUrl);
    console.log("cultureItems", this.cultureItems);
    console.log("END -- VIEW");
    if (this.website != null) {
      this.fetchPageData();
    }
  },
  computed: {
    headers() {
      if (this.isAppMobile) {
        return [
          {
            text: this.pageContent.headers.email,
            align: "start",
            sortable: true,
            value: "email",
          },
          {
            text: this.pageContent.headers.status,
            align: "end",
            sortable: true,
            value: "barcodeStatus",
          },
        ];
      } else {
        return [
          {
            text: this.pageContent.headers.name,
            align: "start",
            sortable: true,
            value: "first_name",
          },
          {
            text: "",
            align: "start",
            sortable: true,
            value: "last_name",
          },
          {
            text: this.pageContent.headers.email,
            align: "start",
            sortable: true,
            value: "email",
          },
          {
            text: this.pageContent.headers.status,
            align: "end",
            sortable: true,
            value: "barcodeStatus",
          },
        ];
      }
    },
    clangID() {
      return this.$store.getters["liApiStore/getClangID"];
    },
    relations() {
      return this.$store.getters["liApiStore/getRelations"];
    },
    invitations() {
      return this.$store.getters["liApiStore/getInvitations"];
    },
    mappedInvitations: {
      get() {
        if (this.invitations != null && this.relations != null) {
          return this.relations.filter((_relation) => _relation.ticket_state == "uitgenodigd");
        } else {
          return null;
        }
      },
    },
    timeSlots() {
      return this.$store.getters["liApiStore/getTimeSlots"];
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
  },
  methods: {
    getTimeSlot(_customerClangId) {
      const invitee = this.invitations.find((_inv) => _inv.id == _customerClangId);
      if (invitee == undefined) return null;
      const timeslot = invitee.options.CustomerOption.find((_inv) => _inv.name == "timeslot");
      return sprintf(this.pageContent.timeslot, timeslot.value);
    },
    expand(_row) {
      if (this.expanded[0] != undefined && this.expanded[0].clang_id == _row.clang_id) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(_row);
      }
    },
    barcodesStatus(barcodes) {
      const total = _.filter(barcodes, (_code) => {
        if (_code.isUsed === "yes") return _code;
      }).length;

      return sprintf(this.pageContent.barcodeStatusString, total, barcodes.length);
    },
    sendExtraTicket(_relation) {
      this.sendingExtraTicketRelation = _relation.clang_id;
      let data = {
        clangID: this.clangID,
        lang: this.language.toUpperCase(),
        emailID: this.emailInviteId[this.language.toUpperCase()],
        inviteDate: this.localeDateFormat(new Date()),
        ticketQty: 1,
        customers: [
          {
            email: _relation.email,
            firstname: _relation.first_name,
            lastname: _relation.last_name,
            relationID: _relation.clang_id,
            relationshipType: _relation.relationship_type,
            relationshipTypeID: _relation.relationship_type_id,
            ticketQty: 1,
          },
        ],
      };
      this.$store.dispatch("liApiStore/SEND_INVITE", data).then(() => {
        const clangId = this.$store.getters["liApiStore/getClangID"];
        this.$store.dispatch("liApiStore/GET_RELATIONS", clangId).then(() => {
          this.$root.$children[0].$refs.liToast.show({ message: this.pageContent.extraSendingSuccess, color: "success", icon: "mdi-check" });
          setTimeout(() => {
            this.sendingExtraTicketRelation = null;
          }, 500);
        });
      });
    },
    resendTicket(_relation, _ticket) {
      this.resendingBarcode = _ticket.barcode;
      const data = {
        clangID: this.clangID,
        relationID: _relation.clang_id,
        inviteDate: this.localeDateFormat(new Date()),
        ticket: {
          barcode: _ticket.barcode,
          language: _ticket.language ? _ticket.language.toUpperCase() : this.language.toUpperCase(),
          customerClangId: _ticket.customerClangId,
          barcodeClangId: _ticket.clang_id,
          firstname: _relation.first_name,
          lastname: _relation.last_name,
          email: _relation.email,
        },
      };
      this.$store.dispatch("liApiStore/RE_SEND_INVITE", data).then(() => {
        this.$root.$children[0].$refs.liToast.show({ message: this.pageContent.reSendSuccessMessage, color: "success", icon: "mdi-check" });
        setTimeout(() => {
          this.resendingBarcode = null;
        }, 500);
      });
    },
  },
};
</script>
