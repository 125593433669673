<template>
  <v-container class="bg-light pa-0 d-block" fluid fill-height>
    <!-- 
    <pre style="margin-top:100px;">
      claimBarcode = <b> {{ claimBarcode }}</b>
      claimRelationshipType = <b> {{ claimRelationshipType }}</b>
      claimRelationshipTypeName = <b> {{ claimRelationshipTypeName }}</b>
      claimClangId = <b> {{ claimClangId }}</b>
      claimBarcodeClangId = <b> {{ claimBarcodeClangId }}</b>
      validBarcode = <b> {{ validBarcode }}</b>
    </pre> 
    
  -->
    <template v-if="localLoading">
      <div class="d-flex w-100" style="height: 100vh; left: 0; top: 0; width: 100vw; position: fixed; z-index: 10; background-color: rgba(0, 0, 0, 0.3)">
        <v-progress-circular indeterminate color="primary" class="ma-auto" />
      </div>
    </template>
    <template v-if="pageContent != null && validBarcode && validTicket">
      <v-stepper v-model="step" non-linear dark elevation="0">
        <v-stepper-header>
          <v-stepper-step edit-icon="mdi-check" :editable="step > 1" :complete="step > 1" step="1"> {{ pageContent.titleStep1 }} </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step edit-icon="mdi-check" :editable="step > 2" :complete="step > 2" step="2"> {{ pageContent.titleStep2 }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="step > 3">{{ pageContent.titleStep3 }} </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items class="bg-light">
          <!-- STEP 1 - SELECT COUNTRY -->
          <v-stepper-content step="1">
            <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block">
              <v-card-title>
                <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.step1.title }}</h1>
                <p v-if="pageContent.step1.subTitle != ''" class="w-100 text-center mt-3 body-2 mx-auto" v-html="pageContent.step1.subTitle" style="max-width: 960px; width: 90%" />
              </v-card-title>
              <v-card-text class="d-block mx-auto" style="max-width: 960px; width: 90%">
                <v-container fluid class="px-0 mt-3">
                  <ul class="liFlexWrapper medium">
                    <template v-for="(country, index) in pageContent.step1.form.country.options">
                      <li :key="index" class="liListItemClickable" @click.prevent="selectCountry(country)" :class="{ active: selectedCountry == country.value }">
                        <v-card class="pa-3" :elevation="selectedCountry == country.value ? 8 : 1">
                          <h2 class="body-2 text-center font-weight-bold mb-0">{{ country.label }}</h2>
                        </v-card>
                      </li>
                    </template>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions class="align-self-end">
                <v-btn color="primary" elevation="0" class="mx-auto px-md-16" :disabled="selectedCountry == null" @click="step = 2">
                  {{ pageContent.step1.buttons.next }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <!-- STEP 2 - TIMESLOT -->
          <v-stepper-content step="2">
            <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block">
              <v-card-title>
                <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.step2.title }}</h1>
                <p v-if="pageContent.step2.subTitle != ''" class="w-100 text-center mt-3 body-2 mx-auto" v-html="pageContent.step2.subTitle" style="max-width: 960px; width: 90%" />
                <!-- <pre>
                  selectedTimeSlot = <b> {{ selectedTimeSlot }}</b>
                  
                </pre> -->
              </v-card-title>
              <v-card-text class="d-block mx-auto" style="max-width: 960px; width: 90%">
                <template v-if="claimTimeSlots.length > 0">
                  <v-container fluid class="px-0 mt-3">
                    <ul class="liFlexWrapper">
                      <template v-for="(timeSlot, index) in claimTimeSlots">
                        <li :key="index" class="liListItemClickable" @click.prevent="selectTimeSlot(timeSlot)" :class="{ active: selectedTimeSlot != null && selectedTimeSlot.clang_id == timeSlot.clang_id }">
                          <v-card class="pa-3" :elevation="selectedTimeSlot == timeSlot.clang_id ? 8 : 1">
                            <h2 class="text-h6 font-weight-bold mb-3">{{ pageContent.step2.timeSlot }} {{ timeSlot.time_slot }}</h2>
                            <div class="grey--text body-2 mb-3">
                              {{ capitalizeFirstLetter(localeDateFormat(timeSlot.time_slot_date, "manual", "long")) }}<br />
                              {{ timeSlot.time_slot_time }}<br />
                            </div>
                          </v-card>
                        </li>
                      </template>
                    </ul>
                  </v-container>
                </template>
              </v-card-text>
              <v-card-actions class="align-self-end">
                <v-spacer />
                <v-btn text elevation="0" class="mx-auto px-md-16" @click="step = 1">
                  {{ pageContent.step2.buttons.back }}
                </v-btn>
                <v-btn color="primary" elevation="0" :disabled="selectedTimeSlot == null" class="mx-auto px-md-16" @click="step = 3">
                  {{ pageContent.step2.buttons.next }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <!-- STEP 3 - PERSONAL DATA -->
          <v-stepper-content step="3">
            <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block">
              <v-card-title>
                <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.step3.title }}</h1>
                <p v-if="pageContent.step3.subTitle != ''" class="w-100 text-center mt-3 body-3 mx-auto" v-html="pageContent.step3.subTitle" style="max-width: 960px; width: 90%" />
              </v-card-title>
              <v-card-text class="d-block">
                <v-form ref="claimForm" v-model="valid" class="mx-auto" style="max-width: 960px; width: 90%">
                  <v-row dense :class="{ 'mb-2': isAppMobile }">
                    <v-col cols="12" md="6" class="px-0 py-0 pr-md-3">
                      <v-text-field v-model="customer.firstname" :rules="nameRules" :label="pageContent.step3.form.first_name" required />
                    </v-col>
                    <v-col cols="12" md="6" class="px-0 py-0 pl-md-3">
                      <v-text-field v-model="customer.lastname" :rules="lastNameRules" :label="pageContent.step3.form.last_name" required />
                    </v-col>
                  </v-row>
                  <v-row dense class="my-8 my-md-8">
                    <v-col cols="12" md="12" class="px-0 py-0">
                      <h3 class="text-h6 font-weight-bold text--primary">
                        {{ pageContent.step3.form.addressDetails }}
                      </h3>
                    </v-col>

                    <v-col cols="12" md="6" class="px-0 py-0 pr-md-3">
                      <v-text-field v-model="customer.address" :rules="streetRules" :label="pageContent.step3.form.street" required />
                    </v-col>
                    <v-col cols="12" md="2" class="px-0 py-0 pr-md-3">
                      <v-text-field v-model="customer.addressNumber" :rules="numberRules" :label="pageContent.step3.form.number" required />
                    </v-col>
                    <v-col cols="12" md="4" class="px-0 py-0 pr-md-3">
                      <v-text-field v-model="customer.addressNumberSuffix" :label="pageContent.step3.form.addition" />
                    </v-col>
                    <v-col cols="12" md="4" class="px-0 py-0 pr-md-3">
                      <v-text-field v-model="customer.zipCode" :rules="postalRules" :label="pageContent.step3.form.postal" required />
                    </v-col>
                    <v-col cols="12" md="4" class="px-0 py-0 pr-md-3">
                      <v-text-field v-model="customer.city" :rules="cityRules" :label="pageContent.step3.form.city" required />
                    </v-col>
                    <v-col cols="12" md="4" class="px-0 py-0 pr-md-3">
                      <v-select v-model="customer.country" :rules="countryRules" item-text="label" item-value="value" :items="pageContent.step3.form.country.options" :label="pageContent.step3.form.country.label" />
                    </v-col>
                  </v-row>
                  <v-row dense class="my-8">
                    <v-col cols="12" md="12" class="px-0 py-0">
                      <h3 class="text-h6 font-weight-bold text--primary">
                        {{ pageContent.step3.form.contactDetails }}
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6" class="px-0 py-0 pr-md-3">
                      <v-text-field v-model="customer.emailAddress" :rules="emailRules" :label="pageContent.step3.form.email" required />
                    </v-col>
                    <v-col cols="12" md="6" class="px-0 py-0 pl-md-3">
                      <v-text-field v-model="customer.homePhone" :rules="phoneRules" :label="pageContent.step3.form.phone" />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="align-self-end pb-8">
                <v-spacer />
                <v-btn text elevation="0" class="mx-auto px-md-16" @click="step = 2">
                  {{ pageContent.step3.buttons.back }}
                </v-btn>
                <v-btn color="primary" elevation="0" class="mx-auto px-md-16" @click="registerTicket" :disabled="!valid">
                  {{ pageContent.step3.buttons.next }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <!-- STEP 4 - COMPLETE -->
          <v-stepper-content step="4">
            <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block ma-5">
              <v-card-title>
                <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.step4.title }}</h1>
                <p v-if="pageContent.step4.subTitle != ''" class="w-100 text-center mt-3 body-2 mx-auto" v-html="pageContent.step4.subTitle" style="max-width: 960px; width: 90%" />
              </v-card-title>
            </v-card>
          </v-stepper-content>

          <!-- STEP 5 - EMAIL ALREADY IN USE -->
          <v-stepper-content step="5">
            <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block ma-5">
              <v-card-title>
                <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.step4.titleEmailInUse }}</h1>
                <p v-if="pageContent.step4.subTitleEmailInUse != ''" class="w-100 text-center mt-3 body-2 mx-auto" v-html="pageContent.step4.subTitleEmailInUse" style="max-width: 960px; width: 90%" />
              </v-card-title>
              <v-card-actions class="align-self-end">
                <v-spacer />
                <v-btn color="primary" elevation="0" class="mx-auto px-md-16" @click="(step = 3), (customer.emailAddress = null)">
                  {{ pageContent.step4.buttons.tryAgain }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>

    <!-- TICKET ALREADY CLAIMED -->
    <template v-else-if="pageContent != null && validBarcode == true && validTicket == false">
      <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block ma-5">
        <v-card-title>
          <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.claimedBarcodeTitle }}</h1>
          <p v-if="pageContent.claimedBarcodeTitleSubTitle != ''" class="w-100 text-center mt-3 body-2 mx-auto" v-html="pageContent.claimedBarcodeTitleSubTitle" style="max-width: 960px; width: 90%" />
        </v-card-title>
      </v-card>
    </template>

    <!-- INVALID CODE -->
    <template v-else-if="pageContent != null && validBarcode == false && noTimeslotsAvailable == false">
      <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block ma-5">
        <v-card-title>
          <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.invalidBarcodeTitle }}</h1>
          <p v-if="pageContent.invalidBarcodeTitleSubTitle != ''" class="w-100 text-center mt-3 body-2 mx-auto" v-html="pageContent.invalidBarcodeTitleSubTitle" style="max-width: 960px; width: 90%" />
        </v-card-title>
      </v-card>
    </template>

    <!-- NO TIMESLOTS AVAILABLE -->
    <template v-else-if="pageContent != null && validBarcode == false && noTimeslotsAvailable == true">
      <v-card elevation="0" min-height="calc(100vh - 112px)" light class="d-block ma-5">
        <v-card-title>
          <h1 class="w-100 text-center text-h6 text-md-h4 font-weight-bold">{{ pageContent.noTimeslotsAvailableTitle }}</h1>
          <p v-if="pageContent.noTimeslotsAvailableTitleSubTitle != ''" class="w-100 text-center mt-3 body-2 mx-auto" v-html="pageContent.noTimeslotsAvailableTitleSubTitle" style="max-width: 960px; width: 90%" />
        </v-card-title>
      </v-card>
    </template>

    <!-- SOMETHINGS WRONG OR LOADING -->
    <template v-else>
      <div class="d-flex w-100" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="ma-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
//  /^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([.-]?[a-zA-Z0-9]+)*).([A-Za-z]{2,})$/.test(v)
import _ from "lodash";
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "Claim",
  data() {
    return {
      localLoading: true,
      apiPublicUrl: API_URL,
      validTicket: null,
      step: 1,
      valid: false,
      customer: {
        firstname: "",
        lastname: "",
        homePhone: "",
        emailAddress: "",
        address: "",
        addressNumber: "",
        addressNumberSuffix: "",
        zipCode: "",
        city: "",
        country: "",
      },
      selectedCountry: null,
      selectedTimeSlot: null,
      nameRules: [(v) => (!!v && !this.checkForAtSign(v)) || this.liFormatText(this.defaultTextContent.requiredFieldNoAt, [this.pageContent.step3.form.first_name.replace("*", "")])],
      lastNameRules: [(v) => (!!v && !this.checkForAtSign(v)) || this.liFormatText(this.defaultTextContent.requiredFieldNoAt, [this.pageContent.step3.form.last_name.replace("*", "")])],
      streetRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.pageContent.step3.form.street.replace("*", "")])],
      numberRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.pageContent.step3.form.number.replace("*", "")])],
      countryRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.pageContent.step3.form.country.label.replace("*", "")])],
      postalRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.pageContent.step3.form.postal.replace("*", "")])],
      cityRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.pageContent.step3.form.city.replace("*", "")])],
      phoneRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.pageContent.step3.form.phone.replace("*", "")])],
      emailRules: [
        (v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.pageContent.step3.form.email.replace("*", "")]),
        (v) => {
          const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(v) || this.defaultTextContent.emailRequiredField;
          // return /^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([.-]?[a-zA-Z0-9]+)*)+\.([A-Za-z]{2,})$/.test(v) || this.defaultTextContent.emailRequiredField;
        },
      ],
    };
  },
  created() {
    this.fetchURL("/claim-tickets");
  },
  watch: {
    step(val) {
      if (val) {
        this.scrollToTop();
      }
    },
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
    defaultTextContent(val) {
      if (val != null) {
        this.localLoading = false;
      }
    },
  },
  mounted() {
    console.log(`START -- ${this.$options.name} --  VIEW`);
    console.log("language", this.language);
    console.log("website", this.website);
    console.log("fullUrl", this.fullUrl);
    console.log("Barcode Check", this.$route.query);
    console.log("END -- VIEW");
    if (this.website != null) {
      this.fetchPageData();
    }
  },
  computed: {
    claimBarcode() {
      // Complete barcode from $route.query
      return this.$route.query.barcode != undefined && this.$route.query.barcode != "" ? this.$route.query.barcode : null;
    },
    claimRelationshipType() {
      // Only relation type from $route.query get everything before first -
      return this.defaultTextContent != null && this.claimBarcode != null ? this.claimBarcode.charAt(0) : null;
    },
    claimRelationshipTypeSlots() {
      // Get the relation  timeslots from claimRelationshipType
      return this.claimRelationshipType != null ? this.getRelationTypeSlots(this.claimRelationshipType) : null;
    },
    claimRelationshipTypeName() {
      // Get the relation type Name from claimRelationshipType
      return this.claimRelationshipType != null ? this.getRelationTypeLabel(this.claimRelationshipType) : null;
    },
    claimClangId() {
      // Only clang id from $route.query get everything between first - and second -
      return this.claimBarcode != null ? this.claimBarcode.split("-")[1] : null;
    },
    claimBarcodeClangId() {
      // Only clang id from $route.query get everything after :
      return this.claimBarcode != null ? this.claimBarcode.split(":")?.[1] : null;
    },
    validBarcode() {
      if (this.claimTimeSlots.length > 0 && this.claimBarcode != null && this.claimRelationshipTypeName != null && this.claimClangId != null && this.claimBarcodeClangId != null) {
        this.checkValidTicket();
        let regex = /-.*-.*:/i;
        return regex.test(this.claimBarcode);
      } else {
        return false;
      }
    },
    claimTimeSlots() {
      let availableTimeSlots = this.$store.getters["liApiStore/claim_getTimeSlots"];
      let filteredTimeSlots = [];
      if (this.claimRelationshipTypeSlots != null) {
        availableTimeSlots.forEach((_timeSlot) => {
          if (this.claimRelationshipTypeSlots.includes(Number(_timeSlot.time_slot)) && Number(_timeSlot.tickets_claimed) < Number(_timeSlot.tickets_total)) {
            filteredTimeSlots.push(_timeSlot);
          }
        });
      }
      return availableTimeSlots != null ? filteredTimeSlots : null;
    },
    noTimeslotsAvailable() {
      return this.claimTimeSlots != null && this.claimTimeSlots.length == 0 ? true : false;
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
  },
  methods: {
    checkForAtSign(_value) {
      return _value.includes("@");
    },
    checkValidTicket() {
      this.$store.dispatch("liApiStore/CLAIM_CHECK_TICKET", this.claimBarcodeClangId).then((response) => {
        this.validTicket = response.data;
      });
    },
    selectTimeSlot(_timeSlot) {
      this.selectedTimeSlot = _timeSlot;
    },
    selectCountry(_country) {
      this.selectedCountry = _country.value;
      if (_.isEmpty(this.customer.country)) {
        this.customer.country = _country.value;
      }
    },
    registerTicket() {
      if (this.$refs.claimForm.validate()) {
        let data = {
          clangID: this.claimClangId,
          customer: {
            email: this.customer.emailAddress,
            firstname: this.customer.firstname,
            lastname: this.customer.lastname,
            phone: this.customer.homePhone,
            address: {
              street: this.customer.address,
              houseNumber: this.customer.addressNumber,
              suffix: this.customer.addressNumberSuffix,
              zipcode: this.customer.zipCode,
              city: this.customer.city,
              country: this.customer.country,
            },
            relationshipType: this.claimRelationshipTypeName,
            barcode: this.claimBarcode,
          },
          ticketSlot: this.selectedTimeSlot.clang_id,
          ticketSlotName: this.selectedTimeSlot.time_slot,
          language: this.language.toUpperCase(),
          barcodeClangId: this.claimBarcodeClangId,
        };
        this.localLoading = true;
        this.$store.dispatch("liApiStore/CLAIM_TICKET", data).then((response) => {
          this.localLoading = false;
          if (response.data == "Customer already claimed a ticket") {
            this.step = 5;
          }
          if (response.data == "send succesfull") {
            this.step = 4;
          }
        });
      }
    },
  },
};
</script>
