<template>
  <v-container fill-height fluid>
    <v-sheet class="mx-auto" elevation="20">
      <v-skeleton-loader max-width="500" width="500" type="image, table-heading, list-item-two-line"></v-skeleton-loader>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  title: "Referral",
  name: "Referral",
  mounted() {
    if (this.$route.params.identifier != undefined) {
      this.$store
        .dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_jobs_referral",
            where: `name [=>] ${this.$route.params.identifier}`,
            orderBy: "sequence ASC",
            select: "name, referral_to",
          },
          collectionName: `referral_to_${this.$route.params.identifier}`,
        })
        .then((response) => {
          this.$router.push(response[0].referral_to);
        });
    } else {
      this.$router.push("/");
    }
  },
};
</script>
