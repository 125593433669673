var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bg-light pa-3 pa-md-10 d-block",attrs:{"fluid":"","fill-height":""}},[(_vm.pageContent != null)?[_c('div',{staticClass:"w-100 align-self-start"},[(_vm.screenLoading)?_c('div',{staticClass:"liScreenLoading"},[_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('h1',{staticClass:"text-h5 text-md-h4 font-weight-bold w-100 mb-3 d-flex"},[_vm._v(" "+_vm._s(_vm.pageContent.title)+" "),(_vm.relations.length > 0)?_c('span',{staticClass:"ml-3 body-2 align-self-top"},[_vm._v("("+_vm._s(_vm.relations.length)+")")]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto mr-0",attrs:{"elevation":"2","color":"primary","fab":"","dark":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.newItem.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,false,1101449161)},[_c('span',[_vm._v(_vm._s(_vm.pageContent.addRelationLabel))])])],1),_c('v-sheet',{staticClass:"pa-3 pa-md-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.pageContent.searchLabel,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"w-100",class:{ mobile: _vm.isAppMobile },attrs:{"mobile-breakpoint":0,"no-data-text":_vm.pageContent.noDataText,"no-results-text":_vm.pageContent.noResultsText,"headers":_vm.headers,"sort-by":"first_name","loading":_vm.relations == null,"footer-props":{ 'items-per-page-options': _vm.itemsPerPage, 'items-per-page-text': _vm.pageContent.rowsPerPageText, 'items-per-page-all-text': _vm.pageContent.rowsPerPageAllText },"items":_vm.relations,"items-per-page":50,"search":_vm.search},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-3 font-weight-bold my-3",attrs:{"color":"primary","size":"36"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.liGetInitials(((item.first_name) + " " + (item.last_name)))))])]),_c('div',{staticClass:"text-left my-auto"},[_c('span',{staticClass:"font-weight-bold w-100"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")])])],1)]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-none"},[_vm._v(" "+_vm._s(item.last_name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(_vm.isAppMobile)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" "),_c('br'),_c('span',{staticClass:"font-weight-light body-4"},[_vm._v(" "+_vm._s(item.email)+" ")])]):[_vm._v(" "+_vm._s(item.email)+" ")]]}},{key:"item.relationship_type_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"light","x-small":""}},[_vm._v(" "+_vm._s(_vm.getRelationTypeLabel(item.relationship_type_id))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"80px"}},[_c('v-icon',{staticClass:"mr-6 mr-md-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-menu',{attrs:{"close-on-content-click":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-sheet',{staticClass:"pa-2 bg-white"},[_c('p',{staticClass:"px-1 py-1 body-2 mb-0"},[_vm._v(_vm._s(_vm.pageContent.remove.sure))]),_c('div',{staticClass:"pt-3 px-1"},[_c('v-btn',{staticClass:"w-100",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.pageContent.remove.remove))])],1)])],1)],1)]}}],null,false,1407653560)})],1)]:[_c('div',{staticClass:"d-flex w-100 ",staticStyle:{"height":"50vh"}},[_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"indeterminate":"","color":"primary"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }